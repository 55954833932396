<template>
  <draggable :list="coverList" :animation="200" item-key="uid" :group="dragGroup" class="flex flex-wrap" @sort="handleSort">
    <template #item="{ element }">
      <div class="cover-img">
        <ImgInfo
          class="cover-img-info mobile:aspect-[4/3] mobile:w-full"
          :width="isMobile ? '100%' : 300"
          :height="isMobile ? 'auto' : 228"
          :cover="true"
          :data="element"
          @delete="handleDeleteCover"
          @preview="handlePreview(element)"
          @replace="handleReplace"
        />
        <input ref="fileInput" type="file" :accept="accept" class="upload-input" @change="handleFileChange" />
      </div>
    </template>
    <template v-if="showUpload" #footer>
      <div key="footer" class="upload-footer aspect-[4/3] w-[300px] bg-background-2 mobile:w-full">
        <a-upload-dragger
          :file-list="coverList"
          :support-server-render="true"
          :show-upload-list="false"
          :before-upload="beforeUpload"
          :disabled="disabled"
          :multiple="false"
          :accept="accept"
          @dragover.prevent="() => {}"
          @change="handleUploadChange"
          @drop="handleDrop"
        >
          <slot name="upload-contain">
            <div class="px-12 text-text-tertiary">
              <template v-if="isAiEditor">
                <img :src="CoverAiEmptyImg" width="48" height="48" class="mb-4 mt-7" />
                <div class="mb-2 text-level-2 font-semibold">
                  <span class="text-error">*</span>
                  <span class="text-uni-7">Cover image</span>
                </div>
                <div class="ant-upload-hint text-level-7 text-status-alert">Please upload the photo of the final product, not just an AI image.</div>
                <div class="ant-upload-hint text-level-7">4:3 Maximum File Size: 15M</div>
              </template>
              <template v-else>
                <img :src="CoverEmptyImg" width="48" height="48" class="mb-4 mt-7" />
                <div class="mb-2 text-level-2 font-semibold">
                  <span class="text-error">*</span>
                  <span class="text-uni-7">Cover image</span>
                </div>
                <div class="ant-upload-hint text-level-7">Suggest upload 4:3 images</div>
                <div class="ant-upload-hint text-level-7">Max. size: 15 MB</div>
              </template>
            </div>
          </slot>
        </a-upload-dragger>
      </div>
    </template>
  </draggable>
  <client-only>
    <CropCover ref="cropRef" :custom-size="customSize" :is-un-need-size-type="isUnNeedSizeType" @finish="handleFinishCrop" />
  </client-only>
</template>
<script setup lang="ts">
import { handlePictureSuccessUpload } from '@/components/share/utils';
import { handelErrorImg, isValiType } from '@/pages/share/useFormIntegration';
import type { UploadChangeParam, UploadFile } from 'ant-design-vue';
import { cloneDeep } from 'lodash-es';
import type { PropType } from 'vue';
import draggable from 'vuedraggable';
import CoverAiEmptyImg from '~/assets/img/cover-ai-empty.svg';
import CoverEmptyImg from '~/assets/img/cover-empty.svg';
import { ImgLimitSize } from '../const';
import CropCover from './CropCover.vue';
import ImgInfo from './ImgInfo.vue';
const props = withDefaults(
  defineProps<{
    isAiEditor?: boolean;
    isUnNeedSizeType?: boolean; // 是否不需要尺寸类型
    customSize?: number[]; // 自定义 默认裁切尺寸
    disabled?: boolean;
  }>(),
  {
    isAiEditor: false,
    isUnNeedSizeType: false,
    disabled: false,
    customSize: () => [],
  },
);

const { isMobile } = useScreen();
const dragGroup = {
  name: 'project',
  pull: 'false',
  put: true,
  disabledPark: true,
};
let dropUid = '';
const handleSort = (e: any) => {
  if (coverList.value.length === 0) {
    return false;
  }
  let draggingFile: any = null;
  if (e.oldIndex === 0) {
    draggingFile = cloneDeep(coverList.value.shift());
  } else {
    draggingFile = cloneDeep(coverList.value.pop());
  }
  uploadList = [draggingFile];
  dropUid = draggingFile?.uid;
  handlePreview(draggingFile);
};
const coverList = defineModel('coverList', { type: Array as PropType<any>, default: () => [] });
const { handleUploadFile } = useUpload();
const cropRef = ref();
const accept = '.jpg,.jpeg,.png,.gif,.heic';
const showUpload = computed(() => {
  return !coverList.value.length;
});

let uploadList: any = [];
const emptyStatus = ref(true);
const beforeUpload = (file: any) => {
  const { size } = file;
  if (!isValiType(file, accept)) {
    message.error('Format do not meet the requirements');
    return Promise.reject();
  } else if (size > ImgLimitSize) {
    message.error('Uploaded content exceeded size limit');
    return Promise.reject();
  }
};
const handleDrop = () => {
  // console.log('🚀 ~ handleDrop ~ e:', e);
};
const asyncPool = new AsyncPool(6);

const handlePreview = (element: any) => {
  cropRef.value?.open(element, true, props.customSize);
};
const handleFinishCrop = (blobData: Blob) => {
  const thumbUrl = fileToBlob(blobData);
  coverList.value = coverList.value.length ? [...coverList.value] : [...uploadList];
  nextTick(() => {
    let cropFile: any = coverList.value[0];
    Object.assign(cropFile, { url: thumbUrl, percent: 0 });
    asyncPool.run(async () => {
      let contentDisposition = '';
      await handleUploadFile({
        file: blobData,
        contentDisposition,
        onProgress: (percent: number) => {
          Object.assign(cropFile, { percent });
        },
        onFinish: (url: string) => {
          Object.assign(cropFile, { url });
          if (emptyStatus.value) {
            emptyStatus.value = false;
          }
          if (cropFile.uid === dropUid || uploadList[0]?.uid === dropUid) {
            handlePictureSuccessUpload('Cover Image', 'drag');
          } else {
            handlePictureSuccessUpload('Cover Image', 'upload');
          }
          dropUid = '';
        },
        onError: () => {},
      });
    });
  });

  // });
};
const handleDeleteCover = () => {
  coverList.value = [];
  emptyStatus.value = true;
};

const handleUploadChange = async (info: UploadChangeParam<UploadFile<any>>) => {
  uploadList = await handelErrorImg(info.fileList);
  uploadList = uploadList
    .filter((item: any) => isValiType(item, accept))
    .filter((item: any) => {
      const imgExceedLimit = item.type?.includes('image') && item?.size > ImgLimitSize;
      return !imgExceedLimit;
    });
  if (uploadList.length === 0) {
    return false;
  }
  const url = fileToBlob(uploadList[0]);
  uploadList[0].url = url;
  handlePreview(uploadList[0]);
};
const fileInput = ref<HTMLInputElement | null>(null);
const handleReplace = () => {
  fileInput.value?.click();
};
const handleFileChange = async (event: Event) => {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) {
    await beforeUpload(file);
    const info: any = {
      fileList: [file],
    };
    handleUploadChange(info);
  }
  target.value = '';
};
</script>
<style scoped lang="less">
.cover-img {
  .upload-input {
    display: none;
  }
}
</style>
